<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'App',
	components: {},
	mounted() {
		this.updateBodySize();
		window.addEventListener("resize", this.updateBodySize);
	},
	beforeUnmount() {
		window.removeEventListener("resize", this.updateBodySize);
	},
	methods: {
		updateBodySize() {
			document.body.style.height = `${window.innerHeight}px`;
			document.body.style.width = `${window.innerWidth}px`;
		}
	}

}
</script>
<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	width: 100%;
	height: 100%;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	margin: 0 !important;
	padding: 0 !important;
	position: fixed;
}
</style>

import {createRouter, createWebHistory} from 'vue-router';
import CreateContractPreparationPage from '../pages/CreateContractPreparationPage.vue';
const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			component: CreateContractPreparationPage,
			name: 'creatContractPreparation'
		},
		{
			path: '/creatContractPreparation',
			component: CreateContractPreparationPage,
			name: 'creatContractPreparation'
		},
		{
			path: '/:pathMatch(.*)*',  // 捕获所有未定义的路径
			redirect: '/creatContractPreparation'
		}
	]
});

export default router;
<template>
	<div class="root-container">
		<a-spin :spinning="showSpinning">
			<div class="form-header">创建合约准备信息</div>
			<div class="form-content-container">
				<div class="form-content-row">
					<div class="form-content-item">
						<div class="form-content-input-label">合约地址 {{this.contractName ? `(${this.contractName})` : ''}}</div>
						<a-input class="form-input" v-model:value="contractAddress" placeholder="请在此输入合约地址" size="large" @change="this.onContractInputChange"/>
					</div>
					<div class="form-content-item">
						<div class="form-content-input-label">流动池地址</div>
						<a-input v-model:value="pairAddress" placeholder="请在此输入流动池地址" size="large" @change="this.onLpInputChange"/>
					</div>
				</div>
				<div class="form-content-row">
					<div class="form-content-item">
						<div class="form-content-input-label">token 0 {{this.token0Name ? `(${this.token0Name})` : ''}} 地址</div>
						<a-input class="form-input" v-model:value="token0Address" placeholder="请在此流动池的token0地址" size="large"/>
					</div>
					<div class="form-content-item">
						<div class="form-content-input-label">token 1 {{this.token1Name ? `(${this.token1Name})` : ''}} 地址</div>
						<a-input v-model:value="token1Address" placeholder="请在此流动池的token1地址" size="large"/>
					</div>
				</div>
				<div class="form-content-row">
					<div class="form-content-item">
						<div class="form-content-input-label">买入税率</div>
						<a-input class="form-input" v-model:value="buyInTaxRate" placeholder="请在此输入买入税率" size="large" type="number"/>
					</div>
					<div class="form-content-item">
						<div class="form-content-input-label">卖出税率</div>
						<a-input v-model:value="sellOutTaxRate" placeholder="请在此输入卖出税率" size="large" type="number"/>
					</div>
				</div>
				<div class="form-content-row">
					<div class="form-content-item">
						<div class="form-content-input-label">最大买入量</div>
						<a-input class="form-input" v-model:value="maxBuyIn" placeholder="请在此输入最大买入量" size="large"/>
					</div>
					<div class="form-content-item">
						<div class="form-content-input-label">最小买入量</div>
						<a-input v-model:value="minBuyIn" placeholder="请在此输入最小买入量" size="large"/>
					</div>
				</div>
				<div class="form-content-row">
					<div class="form-content-item">
						<div class="form-content-input-label">最大卖出量</div>
						<a-input class="form-input" v-model:value="maxSellOut" placeholder="请在此输入最大卖出量" size="large"/>
					</div>
					<div class="form-content-item">
						<div class="form-content-input-label">最小卖出量</div>
						<a-input v-model:value="minSellOut" placeholder="请在此输入最小卖出量" size="large"/>
					</div>
				</div>
				<div class="form-content-row">
					<div class="form-content-item">
						<div class="form-content-input-label">开盘方式</div>
						<a-select
							ref="select"
							v-model:value="openStyle"
							style="width: 160px"
							@change="onOpenStyleChanged"
							size="large">
							<a-select-option value="blockNumber">区块</a-select-option>
							<a-select-option value="blockTime">区块时间</a-select-option>
							<a-select-option value="method">方法触发</a-select-option>
						</a-select>
					</div>
				</div>
				<div class="form-content-row">
					<div class="form-content-item half-row" v-if="this.openStyle === 'blockNumber'">
						<div class="form-content-input-label">开盘区块高度</div>
						<a-input v-model:value="openBlockNumber" type="number" placeholder="请在此输入开盘区块高度" size="large"/>
					</div>
					<div class="form-content-item half-row" v-if="this.openStyle === 'method'">
						<div class="form-content-input-label">触发函数methodId</div>
						<a-input v-model:value="openMethodId" type="text" placeholder="请在此输入开盘区块高度" size="large"/>
					</div>
					<div class="form-content-item half-row" v-if="this.openStyle === 'method'">
						<div class="form-content-input-label">触发函数methodName</div>
						<a-input v-model:value="openMethodName" type="text" placeholder="请在此输入开盘函数名" size="large"/>
					</div>
					<div class="form-content-item half-row" v-if="this.openStyle === 'method'">
						<div class="form-content-input-label">启动监听的时间</div>
						<div class="form-date-time-picker-row">
							<a-date-picker style="margin-right: 1em;" v-model:value="listenerCreateDate" value-format="YYYY-MM-DD" size="large"/>
							<a-time-picker v-model:value="listenerCreateTime" value-format="HH:mm:ss" size="large"/>
						</div>

					</div>
					<div class="form-content-item half-row" v-if="this.openStyle === 'blockTime'">
						<div class="form-content-input-label">开盘的blockTime时间戳</div>
						<a-input v-model:value="openBlockTime" type="number" placeholder="请在此输入开盘的区块时间" size="large"/>
					</div>
				</div>


				<div class="form-content-row" v-if="this.openStyle === 'method'">
					<div class="form-content-item">
						<div class="form-content-input-label">启动函数 methodId</div>
						<a-input class="form-input" v-model:value="methodId" placeholder="请在此输入启动函数的 methodId" size="large"/>
					</div>
					<div class="form-content-item">
						<div class="form-content-input-label">启动函数 methodName</div>
						<a-input v-model:value="methodName" placeholder="请在此输入启动函数的 methodName" size="large"/>
					</div>
				</div>
				<a-divider>买入配置</a-divider>
				<div class="form-content-row">

					<div class="form-content-item half-row">
						<div class="form-content-input-label">止盈率 (>1)</div>
						<a-input v-model:value="maxLongRate" type="number" placeholder="请在此输入止盈率" size="large"/>
					</div>
					<div class="form-content-item half-row">
						<div class="form-content-input-label">止损率 (0-1)</div>
						<a-input v-model:value="maxShortRate" type="number" placeholder="请在此输入止损率" size="large"/>
					</div>
				</div>
				<div class="form-content-row">

					<div class="form-content-item half-row">
						<div class="form-content-input-label">购买钱包</div>
						<a-input v-model:value="buyInWalletAddress" type="text" placeholder="请在此输入购买钱包" size="large"/>
					</div>
					<div class="form-content-item half-row">
						<div class="form-content-input-label">购买钱包pk</div>
						<a-input v-model:value="buyInWalletPk" type="text" placeholder="请在此输入购买钱包pk" size="large"/>
					</div>
				</div>
				<div class="form-content-row">

					<div class="form-content-item half-row">
						<div class="form-content-input-label">买入量 (0-100%)</div>
						<div style="width: 100%;">
							<a-slider v-model:value="buyInAmountRate" :min="1" :max="100" />
							<a-input-number v-model:value="buyInAmountRate" :min="1" :max="100" style="margin-left: 16px" />
						</div>

					</div>
				</div>

				<div class="form-btn-row">
					<a-button type="primary" size="large" @click="this.saveContractPreparationInfo">提交</a-button>
				</div>
			</div>
		</a-spin>
	</div>
</template>
<script>
import {postRequestAsync, isBscAddress, getErc20ContractName, getTokensInLp} from '@/utils/methods'
import moment from 'moment-timezone';
export default {
	name: 'CreateContractPreparationPage',
	props: {
		msg: String
	},
	data() {
		return {
			contractAddress: '',
			pairAddress: '',
			token0Address: '',
			token1Address: '',
			methodId: '',
			methodName: '',
			buyInTaxRate: 0,
			sellOutTaxRate: 0,
			maxBuyIn: 0,
			minBuyIn: 0,
			maxSellOut: 0,
			minSellOut: 0,
			openStyle: 'blockNumber',
			openBlockNumber: 0,
			openMethodId: 0,
			openMethodName: '',
			openBlockTime: 0,
			showSpinning: false,
			contractName: '',
			token0Name: '',
			token1Name: '',
			listenerCreateDate: null,
			listenerCreateTime: null,
			maxLongRate: 0,
			maxShortRate: 0,
			buyInWalletAddress: '',
			buyInWalletPk: '',
			buyInAmountRate: 100
		};
	},
	methods: {
		// onDateChanged(event) {
		// 	debugger
		// 	console.log(event.target.value)
		// },
		onOpenStyleChanged(e) {

		},
		async saveContractPreparationInfo () {
			try {
				console.log(this.listenerCreateTime)
				console.log(this.listenerCreateDate)
				if (!isBscAddress(this.contractAddress)) {
					return this.$message.error('合约地址不正确')
				}
				if (!isBscAddress(this.pairAddress)) {
					return this.$message.error('流动池地址不正确')
				}
				if (!isBscAddress(this.token0Address)) {
					return this.$message.error('token0 池地址不正确')
				}
				if (!isBscAddress(this.token1Address)) {
					return this.$message.error('token1 池地址不正确')
				}

				const listenerCreateString = `${this.listenerCreateDate} ${this.listenerCreateTime}`
				const momentObj = moment(listenerCreateString, 'YYYY-MM-DD HH:mm:ss');
				const listenerCreateTimestamp = momentObj.valueOf();
				const toSend = {
					contractInfo: {
						contractAddress: this.contractAddress,
						pairAddress: this.pairAddress,
						token0Address: this.token0Address,
						token1Address: this.token1Address,
						buyInTaxRate: this.buyInTaxRate,
						sellOutTaxRate: this.sellOutTaxRate,
						maxBuyIn: this.maxBuyIn,
						minBuyIn: this.minBuyIn,
						maxSellOut: this.maxSellOut,
						minSellOut: this.minSellOut,
						openStyle: this.openStyle,
						listenerCreateTimestamp: listenerCreateTimestamp,
						listenerCreateTimeString: listenerCreateString,
						maxLongRate: this.maxLongRate,
						maxShortRate: this.maxShortRate,
						buyInWalletAddress: this.buyInWalletAddress,
						buyInWalletPk: this.buyInWalletPk,
						buyInAmountRate: this.buyInAmountRate
					}
				}
				if (this.openStyle === 'blockNumber') {
					toSend.contractInfo.openBlockNumber = this.openBlockNumber
				} else if (this.openStyle === 'blockTime') {
					toSend.contractInfo.openBlockTime = this.openBlockTime
				} else if (this.openStyle === 'method') {

					if (!this.methodId) {
						return this.$message.error('methodId 不能为空')
					}
					if (!this.methodName) {
						return this.$message.error('methodName 不能为空')
					}
					const methods = []
					methods.push({
						methodId: this.methodId,
						methodName: this.methodName
					})
					toSend.contractInfo.methods = methods
				}
				this.showSpinning = true
				const result = await postRequestAsync('config/createContractPreparationInfo', toSend)
				if (result.code === 200) {
					return this.$message.success('提交成功！')
				}
				if (result.code === 201) {
					return this.$message.error('代币已存在！')
				}
			} catch (e) {
				console.log(e.message)
			} finally {
				this.showSpinning = false
			}

		},
		async onContractInputChange(event) {

			if (isBscAddress(event.target.value)) {
				this.contractName = await getErc20ContractName(event.target.value)
			}
			// debugger
		},
		async onLpInputChange (event) {
			if (isBscAddress(event.target.value)) {
				this.showSpinning = true
				const {token0, token1} = await getTokensInLp(event.target.value)
				this.showSpinning = false
				this.token0Address = token0
				this.token1Address = token1
				this.token0Name = await getErc20ContractName(this.token0Address)
				this.token1Name = await getErc20ContractName(this.token1Address)
			}
		}
	}
}
</script>

<style scoped>
.root-container {
	width: 100%;
	height: 100%;
	padding: 0 20em;
	box-sizing: border-box;
	overflow-y: auto;
}

.form-header {
	font-size: 1.4em;
	padding: 1em;
}

.form-content-container {
	display: flex;
	flex-direction: column;
	align-items: start;
}

.form-content-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 0.8em;
}

.form-btn-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 0.8em;
	align-items: center;
	justify-content: center;
	margin-top: 3em;
	padding-bottom: 10em;
}

.form-content-item {
	display: flex;
	flex-direction: column;
	font-size: 1em;
	flex: 1;
	justify-content: center;
	align-items: start;
	padding: 0 0.4em;
	box-sizing: border-box;
}

.form-content-input-label {
	word-break: keep-all;
	padding-right: 1em;
	font-size: 1em;
	white-space: nowrap;
	margin-bottom: 0.2em;
}
.form-input{
	margin-right: 1em;
}

.half-row{
	flex: 0.5 !important;
}

.form-date-time-picker-row{
	display: flex;
	flex-direction: row;
}
</style>
import { createApp } from 'vue'
import App from './App.vue'

import router from '@/utils/router';
import Antd from 'ant-design-vue';
import store from '@/utils/store';

const app = createApp(App);

app.use(router); // 使用Vue Router插件
app.use(Antd)
app.use(store)

app.mount('#app');

const server = 'https://bibei-be.nebulacloud.win:3000'
const {Web3} = require('web3')
const IERC20 = require("../contracts/IERC20.json");
const PancakePair = require("../contracts/PancakePair.json");

const web3Instance = new Web3('https://intensive-divine-butterfly.bsc.quiknode.pro/3081968eefde10cf2e6e94a2ed97ee7058a8bf32/');

function getRequest(url, callback) {
	// showLoading()
	const myHeaders = new Headers();
	// myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "124.220.215.2");
	myHeaders.append("Connection", "keep-alive");
	// const sessionId = localStorage.getItem('sessionId')
	// myHeaders.append("sessionId", sessionId);

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	// const self = this

	fetch(`${server}/${url}`, requestOptions)
		.then(response => {
			return response.text(); // 返回响应文本
		})
		.then(result => {
			const responseData = JSON.parse(result)
			console.log(responseData)
			if (responseData && responseData.code === 200) {
				callback('success', responseData)
			} else {
				callback('fail', responseData)
			}

		})
		.catch(error => {
			console.log(error)
			callback('error')
		}).finally(() => {
		// hideLoading();
	});
}

async function getRequestAsync(url, isFullUrl = false) {
	const myHeaders = new Headers();
	// myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "124.220.215.2");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	myHeaders.append("sessionId", sessionId);

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	const requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	// const self = this
	let response
	if (isFullUrl === true) {
		requestOptions.mode = 'no-cors'
		response = await fetch(url, requestOptions)
		return response
	} else {
		response = await fetch(`${server}/${url}`, requestOptions)
		return response.json()
	}

}

function postRequest(url, data, callback) {
	// showLoading()
	var myHeaders = new Headers();
	myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "49.51.186.159");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	myHeaders.append("sessionId", sessionId);

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	const raw = JSON.stringify(data);

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	// const self = this

	fetch(`${server}/${url}`, requestOptions)
		.then(response => {
			return response.text(); // 返回响应文本
		})
		.then(result => {
			const responseData = JSON.parse(result)
			console.log(responseData)
			if (responseData && responseData.code === 200) {
				callback('success', responseData)
			} else {
				callback('fail', responseData)
			}

		})
		.catch(error => {

			console.log('error', error)
			callback('error', error)
		}).finally(() => {
		// hideLoading();
	});
}

async function postRequestAsync(url, data, isFullUrl = false) {
	var myHeaders = new Headers();
	myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "49.51.186.159");
	myHeaders.append("Connection", "keep-alive");
	const sessionId = localStorage.getItem('sessionId')
	myHeaders.append("sessionId", sessionId);

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	var raw = JSON.stringify(data);

	var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	// const self = this
	if (isFullUrl === true) {
		const response = await fetch(url, requestOptions)
		return response
	} else {
		const response = await fetch(`${server}/${url}`, requestOptions)
		return response.json()
	}

}

function isBscAddress(address) {
	// 正则表达式：以0x开头，后面是40个十六进制字符
	const bscAddressRegex = /^0x[a-fA-F0-9]{40}$/;

	// 检查地址是否匹配正则表达式
	return bscAddressRegex.test(address);
}

async function getErc20ContractName (contractAddress) {
	const tokenContract = new web3Instance.eth.Contract(IERC20.abi, contractAddress);
	return await tokenContract.methods.name().call();
}

async function getTokensInLp (pairAddress) {
	const pairContract = new web3Instance.eth.Contract(PancakePair.abi, pairAddress);
	const token0 = await pairContract.methods.token0().call()
	const token1 = await pairContract.methods.token1().call()
	return {token0, token1}
}

export {
	getRequest,
	getRequestAsync,
	postRequest,
	postRequestAsync,
	isBscAddress,
	getErc20ContractName,
	getTokensInLp
}